
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router';
import Axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { addBlockEmail, editBlockEmail, fetchBlockEmail } from 'redux/actions/Manage/EmailBlock/EmailBlock';

const AddEditEmailBlock = () => {
    const { id } = useParams();
    const emailBlock = useSelector((state) => state.emailBlock)

    const [blockData, setBlockData] = useState({
        blockType: 'email',
        email: '',
        status: 'permanentBlock',
        duration: new Date()
    })
    const [error, setError] = useState({
        email: {
            status: false,
            message: null
        },
        duration: {
            status: false,
            message: null
        }
    })
    const history = useHistory();
    const dispatch = useDispatch();


    const handleChange = function (event) {
        const { name, value } = event.target
        setBlockData((prev) => ({ ...prev, [name]: value }))
    }

    const valiDateField = function () {
        let errorData = { ...error }
        let isError = false;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const domainRegex = /^(?!-)[A-Za-z0-9-]{1,63}(?<!-)(\.[A-Za-z0-9-]{1,63})*$/;
        if (blockData.blockType === 'email' && !emailRegex.test(blockData.email)) {
            errorData.email = {
                status: true,
                message: 'Please Enter valid email id'
            }
            isError = true;
        } else if (blockData.blockType === 'domain' && !domainRegex.test(blockData.email)) {
            errorData.email = {
                status: true,
                message: 'Please Enter valid domain id'
            }
            isError = true;
        } else if (blockData.status === 'temporaryBlock' && !blockData.duration) {
            errorData.duration = {
                status: true,
                message: "Duration is required"
            }
            isError = true;
        } else {
            errorData = {
                email: {
                    status: false,
                    message: null
                },
                duration: {
                    status: false,
                    message: null
                }
            }
            isError = false
        }
        setError(errorData)
        return isError
    }

    const handleSubmit = async function (event) {
        event.preventDefault();
        const emailBlockData = {
            ...blockData,
            duration:blockData?.status === "permanentBlock" ? null : blockData?.duration
        } 
        const isError = valiDateField(emailBlockData)
        if (isError) return;
        if(!id){
            dispatch(addBlockEmail(emailBlockData)).then(() => {
                setBlockData({
                    blockType: 'email',
                    email: '',
                    status: 'permanentBlock',
                    duration: new Date()
                })
                history.push('/block-email')
            })
        }else{
            dispatch(editBlockEmail(id,emailBlockData))
        }
    }

    useEffect(() => {
        if (id) {
            dispatch(fetchBlockEmail(id)).then((data)=>{
                setBlockData(data)
            })
        }
    }, [id])

    return (
        <div>
            <Box component={Paper} sx={{ padding: "20px 30px" }}>
                <Typography variant='h6' sx={{ px: "10px", borderBottom: "1px solid" }}>Block Email</Typography>
                <Box sx={{ my: 3 }}>
                    <form action="">
                        <Grid container justifyContent={"space-between"} rowSpacing={2} columnSpacing={2}>
                            <Grid item xs={6}>
                                <FormControl fullWidth size='small'>
                                    <InputLabel id="demo-simple-select-label">Block Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        size='small'
                                        label="Block Type"
                                        name='blockType'
                                        value={blockData?.blockType}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={'email'}>Email Id</MenuItem>
                                        <MenuItem value={'domain'}>Domain Name</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    required
                                    size='small'
                                    type='email'
                                    label={blockData?.blockType === 'email' ? 'Enter Email Id' : 'Enter Domain Id'}
                                    name='email'
                                    value={blockData?.email}
                                    onChange={handleChange}
                                    error={error?.email?.status}
                                    helperText={error?.email?.status && error?.email?.message}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth size='small'>
                                    <InputLabel id="demo-simple-select-label">Block Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        size='small'
                                        label="Block Status"
                                        name='status'
                                        value={blockData?.status}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={'temporaryBlock'}>Temporary</MenuItem>
                                        <MenuItem value={'permanentBlock'}>Permanent</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    size='small'
                                    fullWidth
                                    type='datetime-local'
                                    label="Select date and time"
                                    value={new Date(new Date(blockData?.duration || new Date()).getTime() + (5.5 * 60 * 60 * 1000))
                                        .toISOString()
                                        .slice(0, 16)}
                                    disabled={blockData?.status !== 'temporaryBlock'}
                                    onChange={(event) => {
                                        const newValue = event.target.value;
                                        if (blockData?.status === 'temporaryBlock' && !isNaN(new Date(newValue))) {
                                            setBlockData((prev) => ({ ...prev, duration: new Date(newValue) }));
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Stack justifyContent={"center"} direction={'row'} rowGap={2} columnGap={2} my={3}>
                            <Button variant='outlined' onClick={() => history.push('/block-email')}>Cancel</Button>
                            <Button variant='contained' type='submit' onClick={handleSubmit}>Submit</Button>
                        </Stack>
                    </form>
                </Box>
            </Box>
        </div>
    )
}

export default AddEditEmailBlock